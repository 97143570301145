// @ts-ignore
import { ObjectWithDomElementsT, queryAllWhoHasAttribute } from './utils/dom';
import { FetchMethodE } from './utils/fetch';
import { getCookieValue, isActiveCookie, setCookieByHour } from './utils/cookie';

interface CustomResponseT {
    ip: string;
    isp: string;
    protected:boolean;
}

const API_ROUTE = '/api/privacy-checker';
const COOKIE_NAME = 'hdbn';

class HeaderBanner {
    domElements: ObjectWithDomElementsT;

    response: CustomResponseT;

    origin: string;

    readonly Classes = {
        unprotected: 'header-banner_unprotected',
    } as const;

    readonly Status = {
        unprotected: 'Unprotected',
        protected: 'Protected',
        alternatives: 'Get alternatives',
        getProtected: 'Get protected',
    } as const;

    constructor(
        domElementsDataAttributeName: string,
    ) {
        this.domElements = queryAllWhoHasAttribute(domElementsDataAttributeName);
        this.origin = window.location.origin;

        this.init();
    }

    async init() {
        if (isActiveCookie(COOKIE_NAME)) {
            this.response = JSON.parse(getCookieValue(COOKIE_NAME)) as CustomResponseT;
        } else {
            this.response = await fetch(`${this.origin}${API_ROUTE}`, {
                method: FetchMethodE.GET,
            }).then((res) => {
                if (res.status === 404) {
                    return res;
                }

                return res.json();
            });

            setCookieByHour(COOKIE_NAME, 1, JSON.stringify(this.response));
        }

        this.handleSuccess();
    }

    handleSuccess() {
        if (this.response && this.response.ip) {
            this.domElements.ip.textContent = this.response.ip;
            this.domElements.isp.textContent = this.response.isp;
            // eslint-disable-next-line max-len
            this.domElements.status.textContent = this.response.protected ? this.Status.protected : this.Status.unprotected;
            // eslint-disable-next-line max-len
            this.domElements.link.textContent = this.response.protected ? this.Status.alternatives : this.Status.getProtected;

            if (!this.response.protected) {
                this.domElements.status.classList.add(this.Classes.unprotected);
            }
        }
    }
}

// eslint-disable-next-line no-new
new HeaderBanner('data-js-header-banner');

export {};
