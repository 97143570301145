export const enum FetchMethodE {
    POST = 'POST',
    GET = 'GET',
}

// eslint-disable-next-line max-len
export function fetchCall<T>(url: string, data?: {}, method: FetchMethodE = FetchMethodE.GET): Promise<T> {
    const init: RequestInit = {
        method,
    };

    if (data) {
        const formData = new FormData();

        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                formData.append(key, data[key]);
            }
        }

        init.body = formData;
    }

    return fetch(url, init)
        .then((res) => res.json());
}
